<!-- Companies pages -->
<template>
  <div>
    <transition name="fade-in-up">
      <router-view :router_actions="router_actions"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      router_actions: {
        route_new: 'companies-new',
        route_edit: 'companies-edit'
      }
    };
  },
  methods:{
    
  },
  components: {}
};
</script>
